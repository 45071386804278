.header {
    padding-top: 95px;
    padding-bottom: 72px;
    background-color: #052847;
    background-size: cover;
    background-position: top center;
}
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 6%;
}
.headerTitle {
    margin: 0;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
}