.pageContainer {
    /* display: flex;
    justify-content: center;
    padding-bottom: 4rem;
    padding:0px 8px; */

    /* max-width: 1200px;
    margin: auto;
    padding: 12px;
    margin-top:2rem; */
    max-width: 1200px;
    width: 80%;
    margin: auto;
    padding: 12px;
    margin-top: 2rem;
}
p{
  margin:0px;
  padding:0px;
}
.pageBox {
    /* width:740px; */
    margin-top:38px;
}
.notePageHeading {
    /* text-align: center; */
    font-size: 25px;
    /* font-weight: 700; */
    letter-spacing: -0.18px;
    color: #000;
    line-height: 30px;
    margin-bottom: 30px;
}
ul{
    margin-left:-1px;
}
li {
    /* margin-bottom: 10px; */
    font-size: 16px;
    letter-spacing: -0.18px;
    color: #000;
    line-height: 30px;
    margin-bottom: 20px;
}
@media screen and (max-width: 1650px) {

}

@media screen and (max-width: 700px) {
    .pageContainer {
      display: flex;
      justify-content: center;
      padding-bottom: 4rem;
      padding: 0px 8px;
    }
  
    .pageBox {
      width: 100%; /* Adjust as needed */
      margin-top: 16px;
    }
  
    .notePageHeading {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
  
    ul {
      margin-left: -16px;
    }
  
    li {
      margin-bottom: 6px;
    }
  }
  